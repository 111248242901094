import Vue from 'vue'
import VueRouter from 'vue-router'
import Ecrans from '../views/Ecrans.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/ecrans',
    name: 'Ecrans',
    component: Ecrans
  },
  {
    path: '/ecran/:id/:slug',
    name: 'Ecran',
    component: () => import(/* webpackChunkName: "ecran" */ '../views/Ecran.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
