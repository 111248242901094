export const Outils = {
    data() {
        return {
            muted:false
        }
    },
    mounted() {
    },
    methods: {
        toggleMute() {
            if(this.muted) {
                this.unMutePage();
            } else {
                this.mutePage();
            }
        },
        mutePage() {
            this.muted=true;
            document.querySelectorAll("video, audio").forEach(elem => this.muteMe(elem));
        },
        unMutePage() {
            this.muted=false;
            document.querySelectorAll("video, audio").forEach(elem => this.unMuteMe(elem));
        },
        muteMe(elem) {
            elem.muted = true;
        },
        unMuteMe(elem) {
            elem.muted = false;
        }

    },
    computed: {}
}