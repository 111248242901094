<template>
	<section :data-type="slide.type" :style="'background-image:url('+slide.image+')'">
	</section>
</template>

<script>
	export default {
		props : ['slide']
	}
</script>
<style>
section[data-type="image"] {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
</style>