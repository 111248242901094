<template>
	<section :data-type="slide.type">
		<iframe :src="slide.lien"></iframe>
	</section>
</template>
<script>
	export default {
		props : ['slide']
	}
</script>
<style>
section[data-type="lien"]:before {
	z-index: 1;
	position: absolute;
	top:0;
	left: 0;
	width:100%;
	height: 100%;
	content:'';
	background-color: transparent;
}
section[data-type="lien"] iframe {
	position: absolute;
	top:0;
	left: 0;
	width:100%;
	height: 100%;
	border:0;
}	
</style>