<template>
  <div>
    <br />
    <Logo />
    <br />
    <div class="container">
      <template v-for="ecran in ecrans">
        <router-link
          :to="{name:'Ecran',params:{id:ecran.id,slug:ecran.slug}}"
          ref="ecran"
          class="card lien-ecran mb-5"
        >
          <div class="card-content">
            <span>
              <h3 class="title">{{ecran.libelle}}</h3>
              <h2 class="subtitle">{{ecran.slug}}</h2>
            </span>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ecrans: [],
    };
  },
  mounted() {
    this.demarrer();
    window.addEventListener("keyup", (e) => {
      if (!document.querySelector(".lien-ecran.selected")) {
        document.querySelector(".lien-ecran")?.classList.add("selected");
      }
      let selected = document.querySelector(".lien-ecran.selected");
      console.log(e.key);
      if (e.key == "Enter") {
        e.preventDefault();
        selected.click();
      }
      if (e.key == "ArrowDown") {
        e.preventDefault();
        let next = selected.nextElementSibling;
        if (next) {
          document
            .querySelectorAll(".lien-ecran")
            .forEach((element) => element.classList.remove("selected"));
          next.classList.add("selected");
        }
      }
      if (e.key == "ArrowUp") {
        e.preventDefault();
        let previous = selected.previousElementSibling;
        console.log(previous)
        if (previous) {
          document
            .querySelectorAll(".lien-ecran")
            .forEach((element) => element.classList.remove("selected"));
          previous.classList.add("selected");
        }
      }
    });
    setTimeout(() => {
      document.querySelector(".lien-ecran").classList.add("selected");
    }, 500);
  },
  methods: {
    demarrer() {
      axios.post("ecrans", { clause: { actif: true } }).then((response) => {
        this.ecrans = response.data["ecrans"];
      });
    },
  },
};
</script>
<style lang="scss">
#logo {
  max-width: 50%;
  display: block;
  margin: 0 auto;
}
.lien-ecran {
  border: 20px solid white;
  display: block;
  &.selected {
    border: 20px solid cyan;
  }
}
</style>
