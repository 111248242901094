<template>
	<section :data-type="slide.type">
		<video :poster="slide.video.jpg" ref="video" autoplay>
			<source v-for="source in slide.video.videos" :src="source" :type="typeVideo(source)"></source>
		</video>
		<div v-if="slide.texte" class="texte">
			<big><b v-html="slide.texte.titre_video"></b></big>
			<div v-html="slide.texte.texte_video"></div>
		</div>
	</section>
</template>
<script>
	export default {
		props : ['slide'],
		methods : {
			typeVideo(source) {
				let ext = source.split('.').pop()
				if(ext =='m4v') {
					ext='mp4';
				}
				return 'video/'+ext;
			}
		}		
	}
</script>
<style scoped>
video{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
}	
.texte{
	position: absolute;
	bottom: 0;
	left: 0;
	color:white;
	padding: 2vw;
	padding-top: 3vh;
	white-space: pre;
	width:100%;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

}
</style>