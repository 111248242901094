<template>
  <div>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data(){
      return {
        ecrans : []
      }
    },
    mounted() {
      this.demarrer()
    },
    methods : {
      demarrer() {
        axios.get('ecrans').then(response => {
          this.ecrans = response.data['ecrans'];
        })
      }
    }
  }
</script>
<style lang="scss">
html {
  height: 100%;
}
body {
  background: black;
  --color-main: #fee100;
  --color-hover: #fee100;
  --color-link-hover:#dddddd;
  --color-link-selected:white;
  min-height: 100%;
}
</style>
