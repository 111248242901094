import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import '../node_modules/bulma/css/bulma.css';
import { Outils } from './mixins/outils'

Vue.mixin(Outils);

Vue.prototype.$bus = new Vue();

window.axios = axios.create({
	baseURL: process.env.VUE_APP_API
});

window.axios.interceptors.request.use((config) => {
	let form_data = new FormData();

	for ( var key in config.data ) {
		form_data.append(key, typeof config.data[key] == 'object' ? JSON.stringify(config.data[key]) : config.data[key]);
	}
	config.data = form_data;

	return config;
});

Vue.component('Chargement',() => import('@/components/Chargement.vue'));
Vue.component('Logo',() => import('@/components/Logo.vue'));


const composants = require.context(
  '@/components/Slides/',
  true,
  /^.*\.vue$/
)
composants.keys().forEach(composant => {
	composant = composant.replace('.vue','').replace('./','');
	Vue.component(composant,() => import('@/components/Slides/'+composant+'.vue'));
})




Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
