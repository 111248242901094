<template>
	<section :data-type="slide.type" class="hero is-fullheight">{{slide.texte.transparence_fond}}
		<div class="fond" :style="'background-color:'+slide.texte.couleur_fond">
			<div class="img" :style="'opacity:'+(slide.texte.transparence_fond/100)+';background-size:'+slide.texte.format_fond+';background-image:url('+slide.texte.fond+');'"></div>
		</div>
		<div class="hero-body">
			<div class="container">
				<div class="has-text-centered">
					<div class="logo" v-if="slide.texte.logo" v-html="slide.texte.logo" :style="'color:'+slide.texte.couleur_texte">
					</div>										
					<template v-else>										
						<span class="icon" v-if="slide.texte.icon"  :style="'color:'+slide.texte.couleur_texte">
							<i :class="'fas fa-'+slide.texte.icon"></i>
						</span>
					</template>										
					<h1 class="title is-1" v-html="slide.texte.titre"  :style="'color:'+slide.texte.couleur_texte"></h1>
					<h2 class="subtitle is-2" v-html="slide.texte.contenu"  :style="'color:'+slide.texte.couleur_texte"></h2>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		props : ['slide']
	}
</script>
<style>
section[data-type="texte"] .fond{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
}
section[data-type="texte"] .fond .img{
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
}
section[data-type="texte"] .icon {
	width: 7em;
	height: 7em;
	margin-bottom: 3em;
}
section[data-type="texte"] .icon svg {
	width: 100%;
	height: 100%;
}	
</style>